import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  listScreenings,
  listDraftScreenings,
  create as createScreeningService,
  update as updateScreeningService,
  deleteScreening as deleteScreeningService
} from 'services/screenings'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Screening from 'redesign/types/Screening'

import type {
  UpdateScreeningParams,
  IUseProfileScreeningsReturn
} from './useProfileScreenings.types'

type Props = {
  profileId?: number
  screeningId?: number
  extraQueryKeysToInvalidate?: string[]
}

const useProfileScreenings = ({
  profileId,
  screeningId,
  extraQueryKeysToInvalidate
}: Props): IUseProfileScreeningsReturn => {
  const queryClient = useQueryClient()

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['screenings'] })
    queryClient.invalidateQueries({ queryKey: ['draftScreenings'] })
    extraQueryKeysToInvalidate &&
      extraQueryKeysToInvalidate.forEach(key =>
        queryClient.invalidateQueries({ queryKey: [key] })
      )
  }

  const enabled = Boolean(profileId || screeningId)

  // Get
  const { data: screeningsData, isLoading: screeningsIsLoading } = useQuery({
    queryKey: screeningId ? ['screenings', screeningId] : ['screenings'],
    queryFn: () => {
      const params = {
        profile: profileId,
        populate: ['skills', 'author']
      }
      return listScreenings({
        params: screeningId ? { ...params, id: screeningId } : { ...params }
      })
    },
    enabled
  })
  const screenings = useMemo(() => screeningsData?.data || [], [screeningsData])
  const screeningsCount = useMemo(
    () => screeningsData?.data.length || 0,
    [screeningsData]
  )

  const { data: draftScreeningsData, isLoading: draftScreeningsIsLoading } =
    useQuery({
      queryKey: screeningId
        ? ['draftScreenings', screeningId]
        : ['draftScreenings'],
      queryFn: () => {
        const params = {
          profile: profileId,
          populate: ['skills', 'author']
        }
        return listDraftScreenings({
          params: screeningId ? { ...params, id: screeningId } : { ...params }
        })
      },
      enabled
    })
  const draftScreenings = useMemo(
    () => draftScreeningsData?.data || [],
    [draftScreeningsData]
  )
  const draftScreeningsCount = useMemo(
    () => draftScreeningsData?.data.length || 0,
    [draftScreeningsData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createScreeningService,
    onSuccess: () => {
      message.success('Screening created')
      invalidateQueries()
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Create Screening failed')
    }
  })

  const createScreening = (values: Screening, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateScreeningParams
  >({
    mutationFn: ({ screeningId, values }) =>
      updateScreeningService(screeningId, values),
    onSuccess: () => {
      message.success('Screening updated')
      invalidateQueries()
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Update Screening failed')
    }
  })

  const updateScreening = (
    values: UpdateScreeningParams,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteScreeningService,
    onSuccess: () => {
      message.success('Screening deleted')
      invalidateQueries()
    },
    onError: () => {
      message.error('Delete Screening failed')
    }
  })

  const deleteScreening = (ScreeningId = screeningId) => {
    deleteMutation.mutate(ScreeningId)
  }

  return {
    data: [...screenings, ...draftScreenings],
    dataCount: screeningsCount + draftScreeningsCount,
    isLoading: Boolean(screeningsIsLoading || draftScreeningsIsLoading),
    isMutationLoading: Boolean(
      createMutation.isLoading ||
        updateMutation.isLoading ||
        deleteMutation.isLoading
    ),
    createScreening,
    updateScreening,
    deleteScreening
  }
}

export default useProfileScreenings
