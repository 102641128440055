import * as React from 'react'

import { DropdownFilter } from './DropdownFilter'
import { InputNumber } from './InputNumber'
import { Select, SelectOption } from './Select'

export function rangeFilterToFetchParam (params, key, value) {
  const { from, to } = (value || [])[0] || {}

  if (typeof from === 'undefined' || typeof to === 'undefined') {
    return
  }
  params[`${key}_gte`] = from
  params[`${key}_lte`] = to
}

export function rangeFilterFromQueryString (key, val = '') {
  const range = val
    .split(',')
    .filter(v => Number.parseInt(v, 10) === 0 || Boolean(v))

  if (range.length < 2) {
    return null
  }

  const [from, to] = range
  return {
    [key]: [
      {
        from,
        to
      }
    ]
  }
}

export function rangeFilterToQueryString (val) {
  const { from, to } = (val || [])[0] || {}

  return [from, to].filter(v => v === 0 || Boolean(v)).join(',')
}

export function RangeFilterDropdown (props) {
  const {
    filterKey,
    filters,

    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  } = props

  const filterInputEl = React.useRef(null)

  React.useEffect(() => {
    if (filterInputEl) {
      setTimeout(() => {
        const el = filterInputEl.current
        if (el?.select) {
          el.select()
        } else if (el?.focus) {
          el.focus()
        }
      }, 100)
    }
  }, [filterInputEl])

  const handleSearch = (selectedKeys, confirm) => {
    const { lowerBound } = props

    if (!selectedKeys.length) {
      confirm()
      return
    }

    if (typeof lowerBound !== 'undefined' && !selectedKeys[0].from) {
      selectedKeys[0].from = lowerBound
    }

    const { upperBound } = props
    if (typeof upperBound !== 'undefined' && !selectedKeys[0].to) {
      selectedKeys[0].to = upperBound
    }

    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
  }

  const onChange = type => value => {
    setSelectedKeys([
      {
        ...(selectedKeys[0] || {}),
        [type]: value
      }
    ])
  }

  const renderInput = filters
    ? props => {
      props.value = props.value?.toString()

      return (
        <Select {...props}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.stopPropagation()
              handleSearch(selectedKeys, confirm, filterKey)
            }
          }}
        >
          {filters.map(({ value, text: label }) => (
            <SelectOption
              key={label}
              value={value.toString()}
              data-testid="select-range-option"
            >
              {label}
            </SelectOption>
          ))}
        </Select>
      )
    }
    : props => <InputNumber min={0} {...props} />

  return (
    <DropdownFilter
      handleFilter={() => handleSearch(selectedKeys, confirm, filterKey)}
      handleReset={() => handleReset(clearFilters)}
    >
      {renderInput({
        'data-testid': 'range-from',
        ref: filterInputEl,
        placeholder: 'Range From',
        value: (selectedKeys[0] || {}).from,
        onChange: onChange('from'),
        style: { width: 188, marginBottom: 8, display: 'block' }
      })}

      {renderInput({
        'data-testid': 'range-to',
        placeholder: 'Range To',
        value: (selectedKeys[0] || {}).to,
        onChange: onChange('to'),
        style: { width: 188, marginBottom: 8, display: 'block' }
      })}
    </DropdownFilter>
  )
}
