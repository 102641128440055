import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const readProfile = async (profileId, params) => {
  try {
    const response = await api.get(`/profiles/${profileId}`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const fetchProfile = async params => {
  try {
    const response = await api.get('/profiles', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createProfile = async profile => {
  try {
    const response = await api.post(`/profiles`, profile)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateProfile = async (profileId, values) => {
  try {
    const response = await api.put(`/profiles/${profileId}`, values)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const listProfileSimilarityModels = async params => {
  try {
    const response = await api.get('/profiles/similar/models', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const fetchSimilarProfiles = async (profile, params) => {
  try {
    const response = await api.get(`profiles/${profile}/similar`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const fetchSuggestedProfiles = async (profile, params) => {
  try {
    const response = await api.get(`profiles/${profile}/suggested-profiles`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const readProfileResume = async profileId => {
  try {
    const response = await api.get(
      `/profiles/${profileId}/parse-resume`,
      null,
      {
        retries: 1
      }
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const parseProfileResume = async profileId => {
  try {
    const response = await api.post(`/profiles/${profileId}/parse-resume`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteProfileResume = async profileId => {
  try {
    const response = await api.delete(`/profile/${profileId}/resume`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createXProfileLink = async profile => {
  try {
    return await api.post('/profiles/x-profiles-link', profile)
  } catch (e) {
    handleThrowError(e)
  }
}

export const createXProfileEntry = async payload => {
  try {
    return await api.post(`/profiles/${payload.profile_id}/x-profiles`, payload)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listProfileWithProfileStatus = async params => {
  try {
    const response = await api.get('/profile-statuses', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const getExperts = async () => {
  try {
    return await api.get('/profile/experts')
  } catch (e) {
    handleThrowError(e)
  }
}

export const getXProfiles = async profileId => {
  try {
    return await api.get(`/profile/x-profiles/${profileId}`)
  } catch (e) {
    handleThrowError(e)
  }
}

export const getProfileDuplicates = async profileId => {
  try {
    return await api.get(`/profiles/${profileId}/duplicates`)
  } catch (e) {
    handleThrowError(e)
  }
}

export const markProfileDuplicates = async (masterId, duplicates) => {
  // The "duplicates" parameter is an array of IDs
  try {
    return await api.post(`/profiles/${masterId}/mark-duplicates`, { duplicates })
  } catch (e) {
    handleThrowError(e)
  }
}

export const resetDuplicateStatus = async duplicates => {
  // The "duplicates" parameter is an array of the IDs whose duplicateOf will be reset to "null"
  try {
    return await api.post(`/profiles/reset-duplicate-status`, { duplicates })
  } catch (e) {
    handleThrowError(e)
  }
}

export const getMasterProfiles = async () => {
  try {
    return await api.get('/profile/master-profiles')
  } catch (e) {
    handleThrowError(e)
  }
}

export const getCandidateSkills = async profileId => {
  try {
    return await api.get(`/profiles/${profileId}/candidate-skills`)
  } catch (e) {
    handleThrowError(e)
  }
}

export const getProfileFxtStatus = async profileId => {
  try {
    const { data: lastRecord } = await api.get(`/profile-fxt-histories?profile=${profileId}&_sort=id:desc&_limit=1`)

    if (!Array.isArray(lastRecord)) {
      return lastRecord
    }
    if (lastRecord.length === 0) {
      return false
    }
    return lastRecord[0].action === 'added'
  } catch (e) {
    handleThrowError(e)
  }
}

export const createFxtStatusRecord = async params => {
  try {
    const { data } = await api.post(`/profile-fxt-histories`, params)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}

export const getProfileXTeamerStatus = async profile => {
  try {
    const params = { profile }
    return await api.get(`/x-teamer-statuses`, { params })
  } catch (e) {
    handleThrowError(e)
  }
}

export const requestXProfileService = async ({ profileId }) => {
  try {
    return await api.post(`/profile/${profileId}/profile-request-x-profile`)
  } catch (e) {
    handleThrowError(e)
  }
}
