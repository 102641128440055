import React from 'react'

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { Skill } from 'components/Skill'
import type { classNames } from 'components/Skill/Skill'
import { Tooltip } from 'components/Tooltip'

import type Smartlist from 'redesign/types/Smartlist'

import { plusIconStyle } from './SkillList.module.css'
import { lilacGeode, tulipTree } from 'theme/colors.module.css'

type SmartlistItem = Smartlist['results']

const types: Record<string, { variant: keyof typeof classNames, color: string }> = {
  screened: {
    variant: 'screening',
    color: tulipTree
  },
  qualified: {
    variant: 'qualified',
    color: lilacGeode
  }
}

type SkillListProps = {
  skills?: ListObject[]
  type?: keyof typeof types
  rowData?: SmartlistItem
  limit?: number
  isRowExpanded?: boolean
  onExpandRow?: (expanded: boolean, row: SmartlistItem) => void
}

export type ListObject = {
  id: number
  title: string
}

export const SkillList = ({
  skills,
  type,
  onExpandRow,
  isRowExpanded,
  rowData,
  limit = 0
}: SkillListProps) => {
  if (!skills) {
    return <></>
  }

  const variant = (type && types[type].variant) || 'default'
  const displayLimit = limit || skills.length
  const plusIconColor = { color: (type && types[type].color) || 'blue' }
  const tooltipText =
    'More than 4 skills are available. Expand it to view all skills.'
  const MoreSkillsIcon = isRowExpanded
    ? MinusCircleOutlined
    : PlusCircleOutlined

  return (
    <>
      {skills &&
        skills
          .slice(0, displayLimit)
          .map(skill => (
            <Skill key={skill.id} title={skill.title} variant={variant} />
          ))}
      {skills && skills.length > displayLimit && (
        <Tooltip title={tooltipText} placement="bottomLeft">
          <MoreSkillsIcon
            className={plusIconStyle}
            style={plusIconColor}
            onClick={() => onExpandRow && onExpandRow(!isRowExpanded, rowData)}
          />
        </Tooltip>
      )}
    </>
  )
}
