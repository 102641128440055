import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listSmartlists = async params => {
  try {
    return await api.get('/smartlists', params)
  } catch (error) {
    handleThrowError(error)
  }
}

const getSmartlistSchema = async (key, params) => {
  try {
    return await api.get(`/smartlists/${key}/schema`, params)
  } catch (error) {
    handleThrowError(error)
  }
}

// Retrieve the schema and also populate the filter items that must be fetched from an endpoint
export const getPopulatedSmartlistSchema = async (key, params) => {
  try {
    const schema = await getSmartlistSchema(key, params)

    for (const attribute in schema.data.attributes) {
      const endpoint =
        schema.data.attributes[attribute]?.filter?.possibleValues?.endpoint
      if (endpoint) {
        const results = await api.get(endpoint)
        schema.data.attributes[attribute].filter.possibleValues.items = []

        // Results will be stored as a render-ready array in the new property: possibleValues.items
        for (const record of results.data) {
          const textField =
            typeof record === 'object'
              ? record[
                schema.data.attributes[attribute].filter.possibleValues
                  ?.labelField
              ]
              : record // The endpoint has returned an array of strings or numbers
          const valueField =
            typeof record === 'object'
              ? record[
                schema.data.attributes[attribute].filter.possibleValues
                  ?.valueField
              ]
              : record
          schema.data.attributes[attribute].filter.possibleValues.items.push({
            text: textField ?? valueField ?? '',
            value: valueField ?? textField ?? ''
          })
        }
      }
    }
    return schema
  } catch (error) {
    handleThrowError(error)
  }
}

export const getSmartlistData = async (key, params) => {
  try {
    return await api.get(`/smartlists/${key}`, params)
  } catch (error) {
    handleThrowError(error)
  }
}
