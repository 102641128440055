import React from 'react'

import { Link } from 'gatsby'

import Clipboard from 'components/Clipboard'

import type { INameEmailProps } from './NameEmail.types'

import { nameEmail } from './NameEmail.module.css'

export const NameEmail = (props: INameEmailProps) => {
  const { value: { profileId, name, email } } = props
  return (
    <div>
      <div><Link to={`/profile?id=${profileId}`}>{name}</Link></div>
      <div className={nameEmail}>
        <a href={`mailto:${email}`}>
          {email}
        </a>
        {email && (
          <Clipboard
            text={email}
            iconVisible={false}
            iconStyle={{ visibility: 'var(--icon-visibility, hidden)' }}
          />
        )}
      </div>
    </div>
  )
}
