import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listDraftTechEval = async params => {
  try {
    return await api.get(`/profile-tech-evals/draft`, params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listTechEvals = async params => {
  try {
    return await api.get(`/profile-tech-evals`, params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const create = async params => {
  try {
    const { data } = await api.post(`/profile-tech-evals`, params)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}

export const update = async (id, values) => {
  try {
    const { data } = await api.put(`/profile-tech-evals/${id}`, values)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteTechEval = async id => {
  try {
    const response = await api.delete(`/profile-tech-evals/${id}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const autoSave = async (id, values) => {
  try {
    const { data } = await api.put(`/profile-tech-evals/autosave/${id}`, values)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}
