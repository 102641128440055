import React from 'react'

import { XTeamFitTag } from 'components/XTeamFitTag'

import type { IXTeamFitProps } from './XTeamFit.types'

export const XTeamFit = (props: IXTeamFitProps) => {
  const { value } = props
  return (
    <>
      {value !== null && <XTeamFitTag fit={value} />}
    </>
  )
}
