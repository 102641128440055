import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getSmartlistData } from 'services/smartlists'

import { convertToParams } from 'redesign/utils/helpers/genericTable'

import type { Result } from 'redesign/types/Smartlist'

import type {
  IUseSmartlistDataParams,
  IUseSmartlistDataReturn
} from './useSmartlistData.types'

const useSmartlistData = ({ smartlistKey, filters, sorter, pagination }: IUseSmartlistDataParams) : IUseSmartlistDataReturn => {
  const { data: smartlistData, isLoading } = useQuery({
    queryKey: ['smartlistData', { smartlistKey, filters, pagination, sorter }],
    queryFn: () => getSmartlistData(smartlistKey, {
      params: convertToParams({ filters, sorter, pagination })
    })
  })
  const data: Result[] = useMemo(
    () =>
      // @TODO(TS migration)
      // @ts-expect-error Smartlists is poorly typed in general and should bei improved
      smartlistData?.data?.data.map(item => ({ ...item, key: item.id })) || [],
    [smartlistData]
  )
  const total = useMemo(
    () => smartlistData?.data?.total || null,
    [smartlistData]
  )

  return {
    isLoading,
    data,
    total
  }
}

export default useSmartlistData
