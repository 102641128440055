import React from 'react'

import type { ReactPlayerProps } from 'react-player'

import { TechEvaluationModal } from 'redesign/components/TechEvaluationModal'
import { TechEvaluationPlayerModal } from 'redesign/components/TechEvaluationPlayerModal'

import { useAutoSaveTechEvaluation } from 'redesign/hooks/useAutoSaveTechEvaluation'
import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useFindAndUpdateJobApplication } from 'redesign/hooks/useFindAndUpdateJobApplication'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileExperts } from 'redesign/hooks/useProfileExperts'
import { useProfileFxtStatus } from 'redesign/hooks/useProfileFxtStatus'
import { useProfileTechEvaluations } from 'redesign/hooks/useProfileTechEvaluations'

import type { TechEvaluationsCustomHookData } from 'redesign/types/TechEvaluationsSmartlist'

type Props = {
  customHookData: TechEvaluationsCustomHookData
}

const emptyFunction = () => {}

export const TechEvaluationsOverlay = (props: Props) => {
  const {
    customHookData: {
      profileId,
      isModalOpen,
      closeModal,
      selectedTechEvaluation,
      setSelectedTechEvaluation,
      showVideo,
      setShowVideo,
      videoURL,
      player,
      setPlayer,
    }
  } = props

  const playerRef = (videoPlayer: ReactPlayerProps) => {
    setPlayer(videoPlayer)
  }

  const { data: profileExperts } = useProfileExperts()
  const {
    updateTechEvaluation,
    isMutationLoading
  } = useProfileTechEvaluations({
    extraQueryKeysToInvalidate: ['smartlistData']
  })

  const { findAndUpdateJobApplication } = useFindAndUpdateJobApplication()
  const {
    autoSaveTechEvaluation,
    isLoading: isAutoSaveLoading
  } = useAutoSaveTechEvaluation()

  const { data: roleCandidates, isLoading: isLoadingRoleCandidates } =
    usePartnerRoleCandidates({
      params: {
        profile: profileId,
        bookedAt_null: true,
        closedAt_null: true
      }
    })
  const { data: features } = useFeatureFlags()

  const {
    fxtStatus,
    isLoading: isFxtStatusLoading,
    updateProfileFxtStatus
  } = useProfileFxtStatus({ profileId: Number(profileId) })

  const handleCloseModal = () => {
    closeModal()
    setSelectedTechEvaluation(undefined)
  }

  return (
    <>
      {selectedTechEvaluation?.id && isModalOpen && !isFxtStatusLoading &&
        <TechEvaluationModal
          visible={isModalOpen}
          profileId={profileId}
          techEvaluation={selectedTechEvaluation}
          closeForm={handleCloseModal}
          createTechEvaluation={emptyFunction}
          updateTechEvaluation={updateTechEvaluation}
          autoSaveTechEvaluation={autoSaveTechEvaluation}
          deleteTechEvaluation={emptyFunction}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          profileExperts={profileExperts}
          features={features}
          updateFxtStatus={updateProfileFxtStatus}
          isFxt={fxtStatus}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={isMutationLoading || isAutoSaveLoading}
        />
      }

      <TechEvaluationPlayerModal
        player={player}
        // @TODO(TS migration)
        // @ts-expect-error Is 'playerRef' defined wrong
        // as we expect a legacy ref object here as forced by ReactPlayer lib?
        playerRef={playerRef}
        visible={showVideo}
        videoURL={videoURL}
        onCancel={() => {
          setShowVideo(false)
        }}
      />
    </>
  )
}
