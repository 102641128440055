import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listScreenings = async params => {
  try {
    return await api.get(`/profile-screenings`, params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listDraftScreenings = async params => {
  try {
    return await api.get(`/profile-screenings/draft`, params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const create = async params => {
  try {
    const { data } = await api.post(`/profile-screenings`, params)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}

export const update = async (id, values) => {
  try {
    const { data } = await api.put(`/profile-screenings/${id}`, values)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteScreening = async id => {
  try {
    const response = await api.delete(`/profile-screenings/${id}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const autoSave = async (id, values) => {
  try {
    const { data } = await api.put(`/profile-screenings/autosave/${id}`, values)
    return data
  } catch (e) {
    handleThrowError(e)
  }
}
