import type { BasicPagination, ColumnSorter } from 'redesign/types/GenericTable'
// @TODO(TS migration): specify what each param may represent
export const convertToParams = ({
  filters,
  sorter,
  pagination,
  key = ''
}: {
  filters: any
  sorter: ColumnSorter
  pagination: BasicPagination
  key?: string
}) => {
  const processedFilters = { ...filters }
  for (const filter in processedFilters) {
    if (
      Array.isArray(processedFilters[filter]) &&
      typeof processedFilters[filter][0] === 'object'
    ) {
      processedFilters[filter] = [JSON.stringify(processedFilters[filter][0])]
    }
  }
  const { pageSize, current } = pagination
  const params = {
    ...processedFilters,
    _start: (current - 1) * pageSize,
    _limit: pageSize
  }
  if ('columnKey' in sorter && sorter?.order) {
    params._sort = `${sorter.columnKey}:${
      sorter.order === 'ascend' ? 'asc' : 'desc'
    }`
  }
  if (key.length) {
    params._key = key
  }
  return params
}

export const prepareSorter = (paramString?: string) => {
  if (!paramString?.length) {
    return {}
  }
  const [columnKey, dir = 'asc'] = paramString.split(':')
  const order = dir.toUpperCase() === 'ASC' ? 'ascend' : 'descend'
  return {
    columnKey,
    order
  }
}
// @TODO(TS migration): specify what 'query' may represent
export const parseQuery = (query: any) => {
  const start: any = query?._start || 1
  const limit: any = query?._limit || 10
  const pagination = {
    pageSize: limit,
    current: Math.floor(start / limit) + 1
  }
  const sorter = prepareSorter(query?._sort)
  const filters = { ...query }
  for (const filter in filters) {
    try {
      const value = JSON.parse(filters[filter])
      if (typeof value === 'object') {
        filters[filter] = [{ ...value }]
      }
    } catch {
      // Not a special (e.g. range) filter
    }
  }
  delete filters._sort
  delete filters._start
  delete filters._limit
  return {
    pagination,
    sorter,
    filters
  }
}

// Fill in for empty fields and prepare defaults if any
// @TODO(TS migration): specify what 'queryParams' may represent
export const prepareQueryParams = (queryParams: any, defaultFilters = {}) => ({
  pagination: queryParams?.pagination || {
    pageSize: 10,
    current: 1
  },
  filters:
    'filters' in queryParams ? queryParams.filters : { ...defaultFilters },
  sorter: queryParams?.sorter || {}
})
