import React from 'react'

import { Select, SelectOption } from 'components/Select'

import type { MappedPartnerRole } from 'redesign/types/MappedPartnerRole'

type Props = {
  newPartnerRoleId?: number
  handleRoleSearch: (value: string) => void
  setNewPartnerRoleId: (value: React.SetStateAction<number | undefined>) => void
  filteredPartnerRoles: MappedPartnerRole[]
}

export const TransferModalContent = (props: Props) => {
  const {
    newPartnerRoleId,
    handleRoleSearch,
    setNewPartnerRoleId,
    filteredPartnerRoles,
  } = props

  return (
    <div>
      <h3>Which role would you like to transfer this reservation to?</h3>
      <div>
        <Select
          showSearch
          filterOption={false}
          onSearch={handleRoleSearch}
          placeholder="Select a Role"
          onChange={value => setNewPartnerRoleId(value)}
          value={newPartnerRoleId}
          data-testid="select-partner-role"
          style={{ width: '350px' }}
        >
          {filteredPartnerRoles.map(({ value, label, skills }) => (
            <SelectOption key={value} value={value} data-testid="select-option">
              <>
                <small style={{ color: '#a4a4a4' }}>
                  {label}
                </small>
                <div>{skills}</div>
              </>
            </SelectOption>
          ))}
        </Select>
      </div>
    </div>
  )
}
