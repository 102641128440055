import React from 'react'

import type Smartlist from 'redesign/types/Smartlist'

import type { ListObject } from './SkillList'
import { SkillList } from './SkillList'

type SmartlistItem = Smartlist['results']

type Props = {
  value: ListObject[]
  options?: Options
  itemType?: string
  record: SmartlistItem
  isRowExpanded: boolean
  onExpandRow: (expanded: boolean, row: SmartlistItem) => void
}

type Options = {
  style?: string
  limit?: number
}

export const List = (props: Props) => {
  const { value, options, record, itemType, onExpandRow, isRowExpanded } = props

  if (itemType === 'skill') {
    return (
      <div>
        <SkillList
          skills={value}
          type={options?.style}
          limit={options?.limit || 0}
          onExpandRow={onExpandRow}
          isRowExpanded={isRowExpanded}
          rowData={record}
        />
      </div>
    )
  }

  return <div>{value.join(', ')}</div>
}
