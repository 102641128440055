import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listSmartlists } from 'services/smartlists.js'

import type { IUseSmartlistsReturn } from './useSmartlists.types'

const useSmartlists = (): IUseSmartlistsReturn => {
  // Get smart lists
  const { data: smartlistsData, isLoading } = useQuery({
    queryKey: ['smartlists'],
    queryFn: listSmartlists
  })
  const data = useMemo(() => smartlistsData?.data || [], [smartlistsData])

  return {
    isLoading,
    data
  }
}

export default useSmartlists
