import React from 'react'

import { Select, SelectOption } from 'components/Select'

import { useRoleCandidateCloseReasons } from '../../../../hooks/useRoleCandidateCloseReasons'

type closeReasonOptionType = { value: number; label: string }

type Props = {
  closeReason?: closeReasonOptionType
  setCloseReason?: React.Dispatch<
    React.SetStateAction<closeReasonOptionType | undefined>
  >
}

export const CloseModalContent = (props: Props) => {
  const { closeReason, setCloseReason } = props

  const handleSelectChange = (_: number, option: unknown) => {
    setCloseReason && setCloseReason(option as closeReasonOptionType)
  }

  const { data: closeReasons, isLoading } = useRoleCandidateCloseReasons()

  return (
    <>
      <span>Why do you want to close the role candidate?</span>
      <Select
        placeholder="Select a Reason"
        value={closeReason}
        onChange={handleSelectChange}
        data-testid="select-close-reason"
        style={{ minWidth: '100%' }}
      >
        {!isLoading &&
          closeReasons.map(({ id, summary, origin }) => (
            <SelectOption key={id} value={summary} data-testid="select-option">
              {origin}: {summary}
            </SelectOption>
          ))}
      </Select>
    </>
  )
}
