import React from 'react'

import { VideoCameraOutlined } from '@ant-design/icons'

import { Button } from 'components/Button'

import type { TechEvaluationsCustomHookData } from 'redesign/types/TechEvaluationsSmartlist'

import { recording } from './VideoLink.module.css'

type Props = {
  value: string
  customHookData: TechEvaluationsCustomHookData
}

export const VideoLink = (props: Props) => {
  const { value, customHookData } = props
  const {
    setShowVideo,
    setVideoURL,
    player
  } = customHookData

  return (
    <div>
      {value &&
        <Button
          icon={<VideoCameraOutlined />}
          shape="circle"
          onClick={e => {
            player && player.seekTo(0, 'fraction')
            setVideoURL(value)
            setShowVideo(true)
            e.preventDefault()
            e.stopPropagation()
          }}
          className={recording}
        />
      }
    </div>
  )
}
