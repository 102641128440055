import React from 'react'

import { RoleFitTag } from 'components/RoleFitTag'

import type { RoleFitProps } from './RoleFit.types'

export const RoleFit = ({ value }: RoleFitProps) => {
  if (value === null) {
    return null
  }

  return <RoleFitTag isFit={value} />
}
