import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listNotes } from 'services/notes.js'

const fetchLastNote = async ({ queryKey }: { queryKey: [string, number] }) => {
  const profileId = queryKey[1]
  const params = {
    profile: profileId,
    _sort: 'id:desc',
    _limit: 1,
    populate: ['author']
  }
  return await listNotes({ params })
}

const useLastNote = (profileId: number) => {
  const { data: noteData, isLoading } = useQuery({
    queryKey: ['lastNote', profileId],
    queryFn: fetchLastNote
  })
  const data = useMemo(() => noteData?.data || [], [noteData])

  return {
    isLoading,
    data
  }
}

export default useLastNote
