import React from 'react'

import Avatar from 'components/CavalryAvatar'

type Props = {
  value: {
    userName: string
    email: string
    profileURL: string
  }
}

export const CavalryAvatar = (props: Props) => {
  const { email, profileURL } = props.value
  return (
    <>
      {email && <Avatar email={email} profileURL={profileURL} />}
    </>
  )
}
