import type { MouseEvent } from 'react'
import React from 'react'

import { ContactsOutlined, SolutionOutlined } from '@ant-design/icons'

import { CloseModal } from '../../containers/RoleCandidateCloseModal/RoleCandidateCloseModal'

import { PresentToBizdevModalContainer } from 'redesign/containers/RoleCandidateActionsButtonContainer/containers/PresentToBizdevModalContainer'
import { TransferModal } from 'redesign/containers/RoleCandidateTransferModal/RoleCandidateTransferModal'

import {
  AcceptIcon,
  ClientInterviewIcon,
  CloseIcon,
  PitchIcon,
  PresentToBizdevIcon,
  TransferIcon
} from 'components/Svg'

import { ActionDropdown } from 'redesign/components/ActionDropdown'
import type { OverlayListItem } from 'redesign/components/OverlayList/OverlayList.types'

import type {
  CloseParams,
  TransferParams
} from 'redesign/hooks/useRoleCandidates/useRoleCandidate.types'

import { normalizeBoolean } from 'redesign/utils/helpers'

import type { MutationOptions } from 'redesign/types/Mutation'
import type RoleCandidate from 'redesign/types/RoleCandidate'
import type User from 'redesign/types/User'

type Props = {
  enableNewAction: boolean
  roleCandidateId: number
  profileId: number
  partnerRoleId: number
  authData: User
  value: {
    roleCandidateId: number
    profileId: number
    partnerRoleId: number
    partnerRoleName: string
    canClientInterview: boolean
    canAccept: boolean
    canPitch: boolean
    canClose: boolean
    canTransfer: boolean
    canContact: boolean
    canRequestXProfile: boolean
    canPresentToBizdev: boolean
  }
  handlePitchRoleCandidate: () => void
  isCloseRoleCandidateModalOpen: boolean
  setIsCloseRoleCandidateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isTransferRoleCandidateModalOpen: boolean
  setIsTransferRoleCandidateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  closeRoleCandidateMutation: (params: CloseParams) => void
  transferRoleCandidateMutation: (params: TransferParams) => void
  isPresentToBizdevModalOpen: boolean
  setIsPresentToBizdevModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onContact: (event: MouseEvent) => void
  onRequestXProfile: (event: MouseEvent) => void
  mutationOptions: MutationOptions
  setIsBizdevActionInProgress?: React.Dispatch<React.SetStateAction<boolean>>
  handleClientInterviewCandidate: () => void
  handleAcceptRoleCandidate: () => void
}

export const ActionsContent = (props: Props) => {
  const {
    enableNewAction,
    roleCandidateId,
    profileId,
    partnerRoleId,
    authData,
    value,
    handlePitchRoleCandidate,
    isCloseRoleCandidateModalOpen,
    setIsCloseRoleCandidateModalOpen,
    isTransferRoleCandidateModalOpen,
    setIsTransferRoleCandidateModalOpen,
    isPresentToBizdevModalOpen,
    setIsPresentToBizdevModalOpen,
    closeRoleCandidateMutation,
    transferRoleCandidateMutation,
    onContact,
    onRequestXProfile,
    mutationOptions,
    setIsBizdevActionInProgress,
    handleClientInterviewCandidate,
    handleAcceptRoleCandidate,
  } = props
  const {
    canPitch,
    canClose,
    canTransfer,
    canContact,
    canRequestXProfile,
    canPresentToBizdev,
    canClientInterview,
    canAccept,
  } = value

  const roleCandidate = {
    id: roleCandidateId,
    profile: {
      id: profileId
    }
  } as RoleCandidate

  const actionListItems: OverlayListItem[] = []
  normalizeBoolean(canContact) &&
    actionListItems.push({
      title: 'Contact',
      icon: <ContactsOutlined />,
      onClick: onContact,
      isVisible: enableNewAction
    })
  normalizeBoolean(canRequestXProfile) &&
    actionListItems.push({
      title: 'Request X-Profile',
      icon: <SolutionOutlined />,
      onClick: onRequestXProfile,
      isVisible: enableNewAction
    })
  normalizeBoolean(canPresentToBizdev) &&
    actionListItems.push({
      title: 'Present to BizDev',
      icon: <PresentToBizdevIcon />,
      onClick: (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsPresentToBizdevModalOpen(true)
      },
      isVisible: enableNewAction
    })
  normalizeBoolean(canClientInterview) &&
    actionListItems.push({
      title: 'Client Interview',
      icon: <ClientInterviewIcon />,
      onClick: handleClientInterviewCandidate
    })
  normalizeBoolean(canAccept) &&
    actionListItems.push({
      title: 'Accept',
      icon: <AcceptIcon />,
      onClick: handleAcceptRoleCandidate
    })
  normalizeBoolean(canPitch) &&
    actionListItems.push({
      title: 'Pitch',
      icon: <PitchIcon />,
      onClick: handlePitchRoleCandidate
    })
  normalizeBoolean(canClose) &&
    actionListItems.push({
      title: 'Close',
      icon: <CloseIcon />,
      onClick: (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsCloseRoleCandidateModalOpen(true)
      }
    })
  normalizeBoolean(canTransfer) &&
    actionListItems.push({
      title: 'Transfer',
      icon: <TransferIcon />,
      onClick: (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsTransferRoleCandidateModalOpen(true)
      }
    })

  const isEmpty =
    !normalizeBoolean(canContact) &&
    !normalizeBoolean(canRequestXProfile) &&
    !normalizeBoolean(canPresentToBizdev) &&
    !normalizeBoolean(canPitch) &&
    !normalizeBoolean(canClose) &&
    !normalizeBoolean(canTransfer)

  return (
    <div>
      <ActionDropdown
        isLoading={false}
        isDisabled={isEmpty}
        actionListItems={actionListItems}
      />
      {isCloseRoleCandidateModalOpen && (
        <CloseModal
          isCloseRoleCandidateModalOpen={isCloseRoleCandidateModalOpen}
          setIsCloseRoleCandidateModalOpen={setIsCloseRoleCandidateModalOpen}
          value={value}
          authData={authData}
          closeRoleCandidateMutation={closeRoleCandidateMutation}
        />
      )}
      {isTransferRoleCandidateModalOpen && (
        <TransferModal
          profileId={profileId}
          roleCandidateId={roleCandidateId}
          partnerRoleId={partnerRoleId}
          isTransferRoleCandidateModalOpen={isTransferRoleCandidateModalOpen}
          setIsTransferRoleCandidateModalOpen={
            setIsTransferRoleCandidateModalOpen
          }
          transferRoleCandidateMutation={transferRoleCandidateMutation}
        />
      )}
      {isPresentToBizdevModalOpen && (
        <PresentToBizdevModalContainer
          visible={isPresentToBizdevModalOpen}
          onCancel={() => setIsPresentToBizdevModalOpen(false)}
          roleCandidate={roleCandidate}
          mutationOptions={mutationOptions}
          setIsBizdevActionInProgress={setIsBizdevActionInProgress}
        />
      )}
    </div>
  )
}
