import { useState } from 'react'

import type { ReactPlayerProps } from 'react-player'

import { useModal } from 'hooks/useModal'

import type { TechEval, TechEvaluationsCustomHookReturnValue } from 'redesign/types/TechEvaluationsSmartlist'

// Enable the inside-table components to communicate with the overlay modals
const useTechEvaluationsCustomHook = () : TechEvaluationsCustomHookReturnValue => {
  // Video player communication states (between recording icon and player modal overlay)
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const [videoURL, setVideoURL] = useState<string>('')
  const [player, setPlayer] = useState<ReactPlayerProps | undefined>()
  const [profileId, setProfileId] = useState<number | undefined>()

  const [data, setData] = useState<TechEval>()

  const { isOpen: isModalOpen, closeModal, openModal } = useModal(false)
  const [selectedTechEvaluation, setSelectedTechEvaluation] = useState(undefined)

  return {
    isCustomHookLoading: false,
    customHookData: {
      showVideo,
      setShowVideo,
      videoURL,
      setVideoURL,
      player,
      setPlayer,
      profileId,
      setProfileId,
      data,
      setData,
      isModalOpen,
      closeModal,
      openModal,
      selectedTechEvaluation,
      setSelectedTechEvaluation
    }
  }
}

export default useTechEvaluationsCustomHook
