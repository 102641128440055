import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listScreenings } from 'services/screenings'

import { useUser } from 'redesign/hooks/useUser'

// If current user has any screenings, load only those; otherwise load all screenings recorded
const useScreeningsDefaultFilters = () => {
  const { data: user } = useUser()
  const [defaultFilters, setDefaultFilters] = useState({})
  const { id, username } = user
  const params = { author: id }

  const { isLoading: isDefaultFiltersLoading } = useQuery({
    queryKey: ['screenings', id],
    queryFn: () => listScreenings({ params }),
    onSuccess: data => {
      if (data?.data?.length) {
        setDefaultFilters({ author: username })
      }
    }
  })

  return {
    isDefaultFiltersLoading,
    defaultFilters
  }
}

export default useScreeningsDefaultFilters
