import React from 'react'

import { ScreeningModal } from 'redesign/modules/Profile/containers/InterviewsSection/components/ScreeningModal'

import { useAutoSaveScreening } from 'redesign/hooks/useAutoSaveScreening'
import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useFindAndUpdateJobApplication } from 'redesign/hooks/useFindAndUpdateJobApplication'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileFxtStatus } from 'redesign/hooks/useProfileFxtStatus'
import { useProfileScreenings } from 'redesign/hooks/useProfileScreenings'

import type { ScreeningsCustomHookData } from 'redesign/types/ScreeningsSmartlist'

type Props = {
  customHookData: ScreeningsCustomHookData
}

const emptyFunction = () => {}

export const ScreeningsOverlay = (props: Props) => {
  const {
    customHookData: {
      profileId,
      isModalOpen,
      closeModal,
      selectedScreening,
      setSelectedScreening
    }
  } = props

  const {
    updateScreening,
    isMutationLoading
  } = useProfileScreenings({
    extraQueryKeysToInvalidate: ['smartlistData']
  })

  const { findAndUpdateJobApplication } = useFindAndUpdateJobApplication()
  const {
    autoSaveScreening,
    isLoading: isAutoSaveLoading
  } = useAutoSaveScreening()

  const { data: features } = useFeatureFlags()

  const { data: roleCandidates, isLoading: isLoadingRoleCandidates } =
    usePartnerRoleCandidates({
      params: {
        profile: profileId,
        bookedAt_null: true,
        closedAt_null: true
      }
    })

  const {
    fxtStatus,
    isLoading: isFxtStatusLoading,
    updateProfileFxtStatus
  } = useProfileFxtStatus({ profileId: Number(profileId) })

  const handleCloseModal = () => {
    closeModal()
    setSelectedScreening(undefined)
  }

  return (
    <>
      {selectedScreening?.id && isModalOpen && !isFxtStatusLoading &&
        <ScreeningModal
          visible={isModalOpen}
          profileId={profileId}
          screening={selectedScreening}
          closeForm={handleCloseModal}
          createScreening={emptyFunction}
          updateScreening={updateScreening}
          autoSaveScreening={autoSaveScreening}
          deleteScreening={emptyFunction}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          features={features}
          updateFxtStatus={updateProfileFxtStatus}
          isFxt={fxtStatus}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={isMutationLoading || isAutoSaveLoading}
        />
      }
    </>
  )
}
