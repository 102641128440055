import React, { useEffect, useState } from 'react'

import { RightOutlined } from '@ant-design/icons'
import type { WindowLocation } from '@reach/router'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import { useSmartlists } from '../hooks'

import GenericPage from '../components/GenericPage'

import { Skeleton } from 'redesign/components/Skeleton'

import { useBrowserBackButton } from '../../../../hooks/useBrowserBackButton'

import { parseQuery } from 'redesign/utils/helpers/genericTable'

import {
  arrowIcon,
  container,
  enterButton,
  listItemContainer
} from './SmartlistsPage.module.css'

const EnterSection = () => (
  <div>
    <div className={enterButton}>
      Enter{' '}
      <span className={arrowIcon}>
        <RightOutlined />
      </span>
    </div>
  </div>
)

const MainSmartlistsPage = ({ location }: { location: WindowLocation }) => {
  const { isLoading, data } = useSmartlists()
  const [selectedSchema, setSelectedSchema] = useState<string>()

  useEffect(() => {
    if (location.pathname === '/smartlists') {
      setSelectedSchema('')
    }
  }, [location])

  const handleBack = () => {
    setSelectedSchema('')
  }

  useBrowserBackButton(handleBack)

  const smartLists = data?.map(smartlist => {
    const {
      key,
      info: { emoji, name, description }
    } = smartlist
    return (
      <div
        key={key}
        id={key}
        className={listItemContainer}
        onClick={() => setSelectedSchema(key)}
      >
        <h3>
          {emoji} {name}
        </h3>
        <div>{description}</div>
        <EnterSection />
      </div>
    )
  })

  return (
    <>
      {selectedSchema?.length
        ? (
          <GenericPage selectedSchema={selectedSchema} />
        )
        : (
          <div className="content">
            <h2>Smart Lists</h2>
          Dynamic lists with certain search criteria and filters:
            <div className={container}>
              {isLoading ? <Skeleton active /> : smartLists}
            </div>
          </div>
        )}
    </>
  )
}

const SmartlistsPage = () => {
  // Determine where to direct the user: the smartlist selection page or directly to a specified smartlist search page
  const location = useLocation()
  const query = queryString.parse(location.search || '')

  if (!('_key' in query)) {
    return <MainSmartlistsPage location={location} />
  }

  const selectedSchema = query._key
  delete query._key
  const queryParams = parseQuery(query)

  return (
    <GenericPage
      selectedSchema={String(selectedSchema)}
      queryParams={queryParams}
    />
  )
}

export default SmartlistsPage
