import type { MouseEvent } from 'react'
import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'

import { App } from 'redesign/components'

import { useAcceptRoleCandidate } from 'redesign/containers/RoleCandidateActionsButtonContainer/hooks/useAcceptRoleCandidate'
import { useClientInterviewRoleCandidate } from 'redesign/containers/RoleCandidateActionsButtonContainer/hooks/useClientInterviewRoleCandidate'
import { useContactRoleCandidate } from 'redesign/containers/RoleCandidateActionsButtonContainer/hooks/useContactRoleCandidate'
import { useRequestXProfileForRoleCandidate } from 'redesign/containers/RoleCandidateActionsButtonContainer/hooks/useRequestXProfileForRoleCandidate'

import { ActionsContent } from '../../components/RoleCandidate/ActionsContent'

import { Spin } from 'components/Spin'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useRoleCandidates } from 'redesign/hooks/useRoleCandidates'
import { useUser } from 'redesign/hooks/useUser'

type Props = {
  value: {
    roleCandidateId: number
    profileId: number
    partnerRoleId: number
    partnerRoleName: string
    canClientInterview: boolean
    canAccept: boolean
    canPitch: boolean
    canClose: boolean
    canTransfer: boolean
    canContact: boolean
    canRequestXProfile: boolean
    canPresentToBizdev: boolean
  }
  refetchData: () => void
}

export const Actions = ({ value, refetchData }: Props) => {
  const { modal: { confirm } } = App.useApp()
  const { roleCandidateId, profileId, partnerRoleId, partnerRoleName } = value

  const { data: featureFlags } = useFeatureFlags()
  const enableNewAction = Boolean(
    featureFlags?.RELEASE_CONTACTED_XPROFILE_BIZDEV
  )

  const queryClient = useQueryClient()
  const invalidateQueries = () =>
    queryClient.invalidateQueries({
      queryKey: ['smartlistData']
    })
  const mutationOptions = {
    onSuccess: invalidateQueries,
    onSettled: () => setIsBizdevActionInProgress(false)
  }

  const { data: authData } = useUser()
  const {
    pitchRoleCandidateMutation,
    closeRoleCandidateMutation,
    transferRoleCandidateMutation,
    isLoading
  } = useRoleCandidates(refetchData)

  const { clientInterviewRoleCandidateMutation } = useClientInterviewRoleCandidate()
  const { acceptRoleCandidateMutation } = useAcceptRoleCandidate()

  const {
    contactRoleCandidate,
    isLoading: isContactInProgress
  } = useContactRoleCandidate()

  const {
    requestXProfileForRoleCandidate,
    isLoading: isRequestXProfileInProgress
  } = useRequestXProfileForRoleCandidate()

  const [isBizdevActionInProgress, setIsBizdevActionInProgress] = useState(false)

  const [isCloseRoleCandidateModalOpen, setIsCloseRoleCandidateModalOpen] =
    useState(false)
  const [
    isTransferRoleCandidateModalOpen,
    setIsTransferRoleCandidateModalOpen
  ] = useState(false)
  const [isPresentToBizdevModalOpen, setIsPresentToBizdevModalOpen] =
    useState(false)

  const baseModalProps = {
    icon: <ExclamationCircleOutlined />,
    okText: 'Confirm',
    cancelText: 'Cancel'
  }

  const handleClientInterviewCandidate = () => {
    confirm({
      ...baseModalProps,
      title: 'Pitch',
      content: 'Are you sure you want to client interview this candidate?',
      onOk: () => {
        clientInterviewRoleCandidateMutation({
          profile: profileId,
          partnerRole: partnerRoleId,
          roleCandidateId,
        })
      }
    })
  }

  const handleAcceptRoleCandidate = () => {
    confirm({
      ...baseModalProps,
      title: 'Pitch',
      content: 'Are you sure you want to accept this candidate?',
      onOk: () => {
        acceptRoleCandidateMutation({
          profile: profileId,
          partnerRole: partnerRoleId,
          roleCandidateId: authData.id
        })
      }
    })
  }

  const handlePitchRoleCandidate = () => {
    confirm({
      ...baseModalProps,
      title: 'Pitch',
      content: 'Are you sure you want to pitch this candidate?',
      onOk: () => {
        pitchRoleCandidateMutation({
          profile: profileId,
          partnerRole: partnerRoleId,
          pitchedBy: authData.id
        })
      }
    })
  }

  const onContact = (event: MouseEvent) => {
    event.stopPropagation()

    confirm({
      title: 'Contact',
      content:
        'Please confirm that you have contacted this candidate about this role.',
      onOk: () => {
        contactRoleCandidate(
          {
            roleCandidateId,
            contactedBy: authData.id
          },
          mutationOptions
        )
      }
    })
  }

  const onRequestXProfile = (event: MouseEvent) => {
    event.stopPropagation()

    confirm({
      title: 'Request X-Profile',
      content: `Are you sure you want to Request an X-Profile for "${partnerRoleName}"?`,
      onOk: () => {
        requestXProfileForRoleCandidate(
          {
            roleCandidateId,
            xProfileRequestedBy: authData.id
          },
          mutationOptions
        )
      }
    })
  }

  const isBusy = isLoading || isContactInProgress || isRequestXProfileInProgress || isBizdevActionInProgress
  if (isBusy) {
    return <Spin tip="Loading..." />
  }

  return (
    <div>
      <ActionsContent
        enableNewAction={enableNewAction}
        roleCandidateId={roleCandidateId}
        profileId={profileId}
        partnerRoleId={partnerRoleId}
        authData={authData}
        value={value}
        handleClientInterviewCandidate={handleClientInterviewCandidate}
        handleAcceptRoleCandidate={handleAcceptRoleCandidate}
        handlePitchRoleCandidate={handlePitchRoleCandidate}
        isCloseRoleCandidateModalOpen={isCloseRoleCandidateModalOpen}
        setIsCloseRoleCandidateModalOpen={setIsCloseRoleCandidateModalOpen}
        isTransferRoleCandidateModalOpen={isTransferRoleCandidateModalOpen}
        setIsTransferRoleCandidateModalOpen={
          setIsTransferRoleCandidateModalOpen
        }
        closeRoleCandidateMutation={closeRoleCandidateMutation}
        transferRoleCandidateMutation={transferRoleCandidateMutation}
        onContact={onContact}
        onRequestXProfile={onRequestXProfile}
        isPresentToBizdevModalOpen={isPresentToBizdevModalOpen}
        setIsPresentToBizdevModalOpen={setIsPresentToBizdevModalOpen}
        mutationOptions={mutationOptions}
        setIsBizdevActionInProgress={setIsBizdevActionInProgress}
      />
    </div>
  )
}
