import React, { useState } from 'react'

import { Modal } from 'components/Modal'

import { CloseModalContent } from 'redesign/modules/Smartlists/components/RoleCandidateCloseModal/CloseModalContent'

import type { CloseParams } from 'redesign/hooks/useRoleCandidates/useRoleCandidate.types'

import type User from 'redesign/types/User'

type Props = {
  isCloseRoleCandidateModalOpen: boolean
  setIsCloseRoleCandidateModalOpen: (value: React.SetStateAction<boolean>) => void
  value: {
    roleCandidateId: number
    profileId: number
    partnerRoleId: number
    canPitch: boolean
    canClose: boolean
    canTransfer: boolean
  }
  authData: User
  closeRoleCandidateMutation: (params: CloseParams) => void
}

export const CloseModal = (props: Props) => {
  const {
    isCloseRoleCandidateModalOpen,
    setIsCloseRoleCandidateModalOpen,
    value: {
      roleCandidateId
    },
    authData,
    closeRoleCandidateMutation
  } = props

  const [closeReason, setCloseReason] = useState<{ value: number, label: string } | undefined>()

  const handleOk = async () => {
    await closeRoleCandidateMutation({
      id: roleCandidateId,
      roleCandidateCloseReason: closeReason?.value,
      closedBy: authData.id,
    })
    setIsCloseRoleCandidateModalOpen(false)
  }
  const handleCancel = () => {
    setIsCloseRoleCandidateModalOpen(false)
  }
  return (
    <Modal
      title="Close"
      visible={isCloseRoleCandidateModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !closeReason }}
    >
      <CloseModalContent closeReason={closeReason} setCloseReason={setCloseReason} />
    </Modal>
  )
}
