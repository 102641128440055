import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getPopulatedSmartlistSchema } from 'services/smartlists'

import type { Schema } from 'redesign/types/Smartlist'

import type { IUseSmartlistSchemaReturn } from './useSmartlistSchema.types'

const useSmartlistSchema = (key: string): IUseSmartlistSchemaReturn => {
  const { data: smartlistsData, isLoading } = useQuery({
    queryKey: ['smartlists', key],
    queryFn: () => getPopulatedSmartlistSchema(key)
  })
  const data: Schema = useMemo(
    () => smartlistsData?.data || [],
    [smartlistsData]
  )

  return {
    isLoading,
    data
  }
}

export default useSmartlistSchema
