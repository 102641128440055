import React from 'react'

import type { TechEval } from 'redesign/types/TechEvaluationsSmartlist'

type Props = {
  value: string
  record: TechEval
}

export const Draftable = (props: Props) => {
  const {
    value,
    record: {
      publishedAt
    }
  } = props

  return (
    <>
      {!publishedAt && 'DRAFT - '}
      {value}
    </>
  )
}
