import { useCallback, useEffect } from 'react'

import { useEventListener } from './../useEventListener'

const useBrowserBackButton = handler => {
  const handleBrowserBackButton = useCallback(
    e => {
      e.preventDefault()
      handler()
      window.history.pushState(null, null, window.location.href)
    },
    [handler]
  )

  useEventListener('popstate', handleBrowserBackButton)

  useEffect(() => {
    window.history.pushState(null, null, window.location.href)
  }, [])
}

export { useBrowserBackButton }
