import React from 'react'

import { LeftOutlined } from '@ant-design/icons'

import { Button } from 'components/Button'
import { Spin } from 'components/Spin'

import { useScreeningsDefaultFilters } from '../hooks/useScreeningsDefaultFilters'
import useSmartlistSchema from '../hooks/useSmartlists/useSmartlistSchema'
import { useTechEvaluationsDefaultFilters } from '../hooks/useTechEvaluationsDefaultFilters'

import { prepareQueryParams } from 'redesign/utils/helpers/genericTable'

import type { QueryParams } from 'redesign/types/Smartlist'

import { GenericTable } from './GenericTable'

import { backButton, descriptionBox } from './GenericPage.module.css'

const undefinedHook = () => ({
  isDefaultFiltersLoading: false,
  defaultFilters: {}
})

const smartlistDefaultFilterHooks = {
  screenings: useScreeningsDefaultFilters,
  'tech-evaluations': useTechEvaluationsDefaultFilters
}

type GenericPageProps = {
  selectedSchema: string
  queryParams?: QueryParams | Record<string, never>
}

const GenericPage = ({
  selectedSchema,
  queryParams = {}
}: GenericPageProps) => {
  const { isLoading, data: schema } = useSmartlistSchema(selectedSchema)
  const defaultFiltersHook =
    smartlistDefaultFilterHooks[selectedSchema as keyof typeof smartlistDefaultFilterHooks] || undefinedHook
  const { defaultFilters, isDefaultFiltersLoading } = defaultFiltersHook()

  if (isLoading || isDefaultFiltersLoading) {
    return (
      <div className="spinnerContainer">
        <Spin tip="Loading..." size="large" />
      </div>
    )
  }
  const {
    info: { emoji, name, description }
  } = schema
  const newQueryParams = prepareQueryParams(queryParams, defaultFilters)

  return (
    <div className="content">
      <Button
        className={backButton}
        type="default"
        icon={<LeftOutlined />}
        href="/smartlists"
      >
        Back
      </Button>
      <br />
      <br />
      <div>
        <h2>
          {emoji} {name}
        </h2>
      </div>
      <div className={descriptionBox}>
        <b>Description:</b> {description}
      </div>
      <GenericTable
        smartlistKey={selectedSchema}
        tableSchema={schema}
        queryParams={newQueryParams}
      />
    </div>
  )
}

export default GenericPage
