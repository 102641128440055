import React from 'react'

import { RootComponent } from 'redesign/app/RootComponent'

import SEO from '../components/SEO'

import { SmartlistsPage } from 'redesign/modules/Smartlists'

const Smartlists = () => (
  <>
    <SEO title="Smart Lists" />
    <RootComponent menuSelectedKeys={['smartlists']}>
      <SmartlistsPage />
    </RootComponent>
  </>
)

export default Smartlists
