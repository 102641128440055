import { useState } from 'react'

import { useModal } from 'hooks/useModal'

import type { Screening, ScreeningsCustomHookReturnValue } from 'redesign/types/ScreeningsSmartlist'

// Enable the inside-table components to communicate with the overlay modals
const useScreeningsCustomHook = () : ScreeningsCustomHookReturnValue => {
  const [profileId, setProfileId] = useState<number | undefined>()

  const [data, setData] = useState<Screening>()

  const { isOpen: isModalOpen, closeModal, openModal } = useModal(false)
  const [selectedScreening, setSelectedScreening] = useState<Screening>()

  return {
    isCustomHookLoading: false,
    customHookData: {
      profileId,
      setProfileId,
      data,
      setData,
      isModalOpen,
      closeModal,
      openModal,
      selectedScreening,
      setSelectedScreening
    }
  }
}

export default useScreeningsCustomHook
