import React from 'react'

import type { ILinkProps } from './Link.types'

export const Link = (props: ILinkProps) => {
  const { options, value } = props
  const { labelTemplate, linkTemplate } = options
  // Const [, prop] = labelTemplate.split(':')
  const prop = labelTemplate
  return (
    <div>
      <a href={linkTemplate.replace(':id', value.id)}>
        {value[prop]}
      </a>
    </div>
  )
}
