import React from 'react'

import TechEval from 'components/Profile/TechEvals/TechEval'

import type { TechEvaluationsResponse } from 'redesign/types/TechEvaluationsSmartlist'

type Props = {
  record: TechEvaluationsResponse
}

export const TechEvaluationsExpandableContent = (props: Props) => {
  const { wholeText, qualifiedSkills, supportedByExpert, expertName } = props.record
  const values = {
    body: wholeText,
    skills: qualifiedSkills,
    supportedByExpert,
    expert: {
      fullName: expertName
    }
  }
  return <TechEval values={values} />
}
