import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  listTechEvals,
  listDraftTechEval,
  create as createTechEvaluationService,
  update as updateTechEvaluationService,
  deleteTechEval as deleteTechEvaluationService
} from 'services/techEvals'

import type { MutationOptions } from 'redesign/types/Mutation'
import type TechnicalEvaluation from 'redesign/types/TechnicalEvaluation'

import type {
  UpdateTechEvaluationParams,
  IUseProfileTechEvaluationsReturn
} from './useProfileTechEvaluations.types'

type Props = {
  profileId?: number
  techEvalId?: string
  extraQueryKeysToInvalidate?: string[]
}

const useProfileTechEvaluations = ({
  profileId,
  techEvalId,
  extraQueryKeysToInvalidate
}: Props): IUseProfileTechEvaluationsReturn => {
  const queryClient = useQueryClient()

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['techEvaluations'] })
    queryClient.invalidateQueries({ queryKey: ['draftTechEvaluations'] })
    extraQueryKeysToInvalidate &&
      extraQueryKeysToInvalidate.forEach(key =>
        queryClient.invalidateQueries({ queryKey: [key] })
      )
  }

  const enabled = Boolean(profileId || techEvalId)

  // Get
  const { data: techEvaluationsData, isLoading: techEvaluationsIsLoading } =
    useQuery({
      queryKey: techEvalId
        ? ['techEvaluations', techEvalId]
        : ['techEvaluations'],
      queryFn: () => {
        const params = {
          profile: profileId,
          populate: ['skills', 'author', 'expert']
        }
        return listTechEvals({
          params: techEvalId ? { ...params, id: techEvalId } : { ...params }
        })
      },
      enabled
    })
  const techEvaluations = useMemo(
    () => techEvaluationsData?.data || [],
    [techEvaluationsData]
  )
  const techEvaluationsCount = useMemo(
    () => techEvaluationsData?.data.length || 0,
    [techEvaluationsData]
  )

  const {
    data: draftTechEvaluationData,
    isLoading: draftTechEvaluationsIsLoading
  } = useQuery({
    queryKey: techEvalId
      ? ['draftTechEvaluations', techEvalId]
      : ['draftTechEvaluations'],
    queryFn: () => {
      const params = {
        profile: profileId,
        populate: ['skills', 'author']
      }
      return listDraftTechEval({
        params: techEvalId ? { ...params, id: techEvalId } : { ...params }
      })
    },
    enabled
  })
  const draftTechEvaluation = useMemo(
    () => draftTechEvaluationData?.data || [],
    [draftTechEvaluationData]
  )
  const draftTechEvaluationCount = useMemo(
    () => draftTechEvaluationData?.data.length || 0,
    [draftTechEvaluationData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createTechEvaluationService,
    onSuccess: () => {
      message.success('Technical Evaluation created')
      invalidateQueries()
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Create Technical Evaluation failed')
    }
  })

  const createTechEvaluation = (
    values: TechnicalEvaluation,
    callbacks?: MutationOptions
  ) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateTechEvaluationParams
  >({
    mutationFn: ({ techEvaluationId, values }) =>
      updateTechEvaluationService(techEvaluationId, values),
    onSuccess: () => {
      message.success('Technical Evaluation updated')
      invalidateQueries()
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Update Technical Evaluation failed')
    }
  })

  const updateTechEvaluation = (
    values: UpdateTechEvaluationParams,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteTechEvaluationService,
    onSuccess: () => {
      message.success('Technical Evaluation deleted')
      invalidateQueries()
    },
    onError: () => {
      message.error('Delete Technical Evaluation failed')
    }
  })

  const deleteTechEvaluation = (techEvaluationId = techEvalId) => {
    deleteMutation.mutate(techEvaluationId)
  }

  return {
    data: [...techEvaluations, ...draftTechEvaluation],
    dataCount: techEvaluationsCount + draftTechEvaluationCount,
    isLoading: Boolean(
      techEvaluationsIsLoading ||
      draftTechEvaluationsIsLoading
    ),
    isMutationLoading: Boolean(
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading
    ),
    createTechEvaluation,
    updateTechEvaluation,
    deleteTechEvaluation
  }
}
export default useProfileTechEvaluations
