import React from 'react'

import { ScreeningExpandedRow } from 'redesign/modules/Profile/containers/InterviewsSection/components/ScreeningExpandedRow'

import type { ScreeningsResponse } from 'redesign/types/ScreeningsSmartlist'

type Props = {
  record: ScreeningsResponse
}

export const ScreeningsExpandableContent = (props: Props) => {
  const { wholeText, screenedSkills } = props.record
  const values = {
    body: wholeText,
    skills: screenedSkills
  }

  return <ScreeningExpandedRow screening={values} />
}
