import React, { useState } from 'react'

import { SectionCard } from 'redesign/components'

import { RoleSuggestionsTable } from 'redesign/components/RoleSuggestionsTable'

import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useRoleCandidates } from 'redesign/hooks/useRoleCandidates'
import { useRoleSuggestions } from 'redesign/hooks/useRoleSuggestions'
import { useUser } from 'redesign/hooks/useUser'

import { ORIGINS } from 'redesign/utils/roleCandidateUtils'

import type { ExpiredReservationsResponse } from 'redesign/types/ExpiredReservations'

type Props = {
  record: ExpiredReservationsResponse
}

export const ExpiredReservationsExpandableContent = ({ record }: Props) => {
  const {
    nameEmail: { profileId }
  } = record
  const [selectedModels, setSelectedModels] = useState<string[]>()
  const {
    data,
    isLoading,
    error,
    unhideSuggestion,
    hideSuggestion,
    hideUnhideIsLoading
  } = useRoleSuggestions({ profile: profileId, models: selectedModels })
  const { data: authData } = useUser()
  const { id: userId } = authData

  const {
    transferRoleCandidateMutation,
    shortlistRoleCandidateMutation,
    reserveRoleCandidateMutation,
    isLoading: roleCandidateActionsLoading
  } = useRoleCandidates()

  const {
    data: roleCandidates,
    error: roleCandidatesError,
    isLoading: roleCandidatesIsLoading
  } = usePartnerRoleCandidates({ params: { profile: profileId } })

  return (
    <SectionCard isLoading={false}>
      <RoleSuggestionsTable
        data={data}
        roleCandidates={roleCandidates}
        profileId={profileId}
        handleHide={hideSuggestion}
        handleUnhide={unhideSuggestion}
        handleReserve={values =>
          reserveRoleCandidateMutation({
            ...values,
            reservedBy: userId,
            origin: {
              type: ORIGINS.SMARTLIST.type[1],
              name: `Expired Reservations`,
              user: authData.username
            }
          })
        }
        handleShortlist={values =>
          shortlistRoleCandidateMutation({
            ...values,
            shortlistedBy: userId,
            origin: {
              type: ORIGINS.SMARTLIST.type[1],
              name: `Expired Reservations`,
              user: authData.username
            }
          })
        }
        handleTransfer={transferRoleCandidateMutation}
        isLoading={
          isLoading ||
          hideUnhideIsLoading ||
          roleCandidatesIsLoading ||
          roleCandidateActionsLoading
        }
        error={error || roleCandidatesError}
        setSelectedModels={setSelectedModels}
        selectedModels={selectedModels}
        authData={authData}
      />
    </SectionCard>
  )
}
