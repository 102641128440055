import React from 'react'

import { Skill } from 'components/Skill'

import type {
  GroupedSkills,
  ITopSkillsProps,
  SkillItem
} from './TopSkills.types'

const skillDisplayLimit = 4

// Merge duplicate records in each group (type) separately because each handles duplicates differently:
// For CV skills the duration will be the greater number, but for experiences the durations will be added up
const mergeDuplicatesInGroups = (skills: GroupedSkills): GroupedSkills => {
  const distinctSkills: GroupedSkills = {
    cv: [],
    experience: []
  }

  for (const skill of skills.cv) {
    const match = distinctSkills.cv.find(item => item.id === skill.id)
    if (match) {
      const index = distinctSkills.cv.indexOf(match)
      distinctSkills.cv[index].monthsExperience =
        Math.max(distinctSkills.cv[index].monthsExperience, skill.monthsExperience)
    } else {
      distinctSkills.cv.push({ ...skill })
    }
  }

  for (const skill of skills.experience) {
    const match = distinctSkills.experience.find(item => item.id === skill.id)
    if (match) {
      const index = distinctSkills.experience.indexOf(match)
      distinctSkills.experience[index].monthsExperience += skill.monthsExperience
    } else {
      distinctSkills.experience.push({ ...skill })
    }
  }
  return distinctSkills
}

// Merge inter-group duplicates by choosing the greater duration of experience
const mergeSkillGroups = (skills: GroupedSkills): SkillItem[] => {
  const result: SkillItem[] = [...skills.cv]

  for (const skill of skills.experience) {
    const match = result.find(item => item.id === skill.id)
    if (match) {
      const index = result.indexOf(match)
      result[index].monthsExperience =
        Math.max(result[index].monthsExperience, skill.monthsExperience)
    } else {
      result.push({ ...skill })
    }
  }
  return result
}

const processSkills = (skillRecords: SkillItem[]) => {
  const skills: GroupedSkills = {
    cv: [],
    experience: []
  }
  skillRecords.map(skill => skills[skill.type].push(skill))

  const distinctSkills = mergeDuplicatesInGroups(skills)
  const finalList = mergeSkillGroups(distinctSkills)

  return finalList.sort((a: SkillItem, b: SkillItem) => b.monthsExperience - a.monthsExperience)
}

const TopSkills = ({ value: skillList }: ITopSkillsProps) => {
  if (!skillList?.length) {
    return <></>
  }
  const skills = processSkills(skillList)

  return (
    <div>
      {
        skills
          .slice(0, skillDisplayLimit)
          .map(skill => (
            <Skill key={skill.id} title={skill.title} variant="default" />
          ))
      }
    </div>
  )
}

export default TopSkills
